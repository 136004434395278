<template>
  <div class="orderList-view view pa24">
    <el-row :gutter="24">
    </el-row>
    <!-- :spanMethod="objectSpanMethod" -->
    <commonTable :tableData="tableData"
                 @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange"
                 :currentPage="pageNum"
                 :loading="loading"
                 :total="total"
                 @handleSelectionChange="handleSelectionChange"
                 ref="commonTable"
                 >
      <template v-slot:table>
        <el-table-column
          type="selection"
          width="55"
        >
        </el-table-column>
        <el-table-column prop="createTime" width="100" align="center" label="创建时间">
          <template slot-scope="scope">
            {{scope.row.createTime}}
          </template>
        </el-table-column>
        <el-table-column prop="title" align="center" label="页面标题">
        </el-table-column>
        <el-table-column prop="title" align="center" label="缩略图">
          <template slot-scope="scope">
            <img :src="scope.row.coverImg" class="coverImg" />
          </template>
        </el-table-column>
        <el-table-column prop="batch" align="center" label="批次号" width="250">
          <template slot-scope="scope">
            <template v-if="scope.row.lotNumberList && scope.row.lotNumberList.length">
              <div v-for="item in scope.row.lotNumberList" :key="item.lotNumber" class="lotSpan" >
                <span @click="goHandlePageList(item.lotNumber, scope.row.id, item.isRead, item.isBind, item.type)">
                  {{item.lotNumber}} ({{item.count}})
                 {{item.isRead ? '(需回读)' : ''}}
                 {{ item.remark ? `[${item.remark}]` : ''}}
                </span>
                <span v-if="item.isBind" @click="getBindLotNumber(item.bindLotNumber)">
<!--                   <el-tooltip class="item" effect="dark" :content="item.bindLotNumber" placement="right">-->
                    <img  class="linkIcon" src="../../../assets/images/link.png"  />
<!--                  </el-tooltip>-->
                </span>
                 <span v-if="item.count!==item.allNum" style="position:relative; top:10px;">
                   <el-progress :width="40" :height="40" type="circle" :percentage="item.percent"></el-progress>
                 </span>
              </div>
            </template>
          </template>
        </el-table-column>
<!--        <el-table-column prop="batch" align="center" label="绑定内部页面">-->
<!--          <template slot-scope="scope">-->
<!--            <span v-for="item in scope.row.pages" :key="item.id">{{ item.name }}、</span>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column prop="remark" align="center" label="备注">
        </el-table-column>
<!--        <el-table-column prop="integral" align="center" label="页面积分">-->
<!--        </el-table-column>-->
        <el-table-column align="center" label="操作" width="250">
          <template slot-scope="scope">
              <el-button type="text" size="mini" @click="createHandle(scope.row)" v-if="!scope.row.batch" :disabled="!hasPermi">生成标识</el-button>
              <el-button type="text" size="mini" @click="editPage(scope.row)" :disabled="!hasPermi">编辑页面</el-button>
              <el-button type="text" size="mini" @click="delCreatePage(scope.row)" :disabled="scope.row.lotNumberList && scope.row.lotNumberList.length !== 0 && !hasPermi">删除</el-button>
              <el-button type="text" size="mini" @click="lookPage(scope.row)">查看</el-button>
              <!-- <el-button type="text" size="mini" @click="bindDistributor(scope.row)">绑定经销商</el-button> -->
<!--              <el-button type="text" size="mini" @click="bindInsidePage(scope.row)">绑定内部页</el-button>-->
<!--              <el-button type="text" size="mini" @click="checkRecord(scope.row)">积分记录</el-button>-->

          </template>
        </el-table-column>
      </template>
    </commonTable>

    <!--生成标识弹窗-->
    <el-dialog
      title="生成标识"
      width="800px"
      ref="form"
      :visible.sync="dialogFormVisible"
      :append-to-body='true'
      top="10vh"
    >
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
        <el-form-item prop="type" label="类型">
         <el-select v-model="ruleForm.type" placeholder="请选择类型" @change="getUsedCount">
            <el-option
              v-for="item in TypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <span style="padding-left: 20px;">可使用数量: {{typeUsedCount}}</span>
        </el-form-item>
        <el-form-item prop="number" label="数量">
          <el-input v-model="ruleForm.number" :readonly="ruleForm.isAppoint" placeholder="请输入数量"></el-input>
        </el-form-item>
        <el-form-item prop="isAppoint" label="是否指定文件">
          <el-radio v-model="ruleForm.isAppoint" :label="false" @change="changeIsAppoint">随机</el-radio>
          <el-radio v-model="ruleForm.isAppoint" :label="true" @change="changeIsAppoint">指定标识</el-radio>
        </el-form-item>
        <el-form-item label="上传文件" v-if="ruleForm.isAppoint">
          <el-button @click="downLoadFile" style="float: left; margin-right: 10px; border-radius: 8px;">下载模版</el-button>
          <!-- :data="{num: this.ruleForm.number}" -->
          <el-upload
            class="upload-demo"
            :action="uploadUrl"
            :headers="upParams"
            :on-success="handleSuccess"
            :limit ="1"
            :on-remove="handleRemove"
            :before-upload="beforeUpload"
            :on-exceed="handleExceed"
            :show-file-list="true"
            :file-list="fileList">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传xlsx/xls文件</div>
          </el-upload>
        </el-form-item>
        <el-form-item prop="scanNum" label="失效次数">
          <el-input v-model="ruleForm.scanNum" placeholder="请输入失效次数"></el-input>
        </el-form-item>

         <el-form-item prop="outValue" label="扫码提醒阈值">
           <el-input v-model="ruleForm.outValue" placeholder="请输入数字"></el-input>
         </el-form-item>

         <el-form-item prop="verificationNum" label="验证次数">
           <el-input v-model="ruleForm.verificationNum" placeholder="请输入数字"></el-input>
         </el-form-item>

         <!-- <el-form-item prop="isRead" label="是否回读">
          <el-select v-model="ruleForm.isRead" placeholder="请选择">
            <el-option label="是" :value="true"></el-option>
            <el-option label="不需要回读" :value="false"></el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item prop="idenModelId" label="查询模板">
         <el-select v-model="ruleForm.idenModelId" placeholder="请选择类型">
            <el-option
              v-for="item in xiTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
           <span style="padding-left: 20px; color: #8A8A8A;">(顶级节点查询模版)</span>
        </el-form-item>
        <!-- <el-form-item prop="dealerId" label="经销商">
         <div class="disflex">
           <el-select v-model="ruleForm.dealerId" placeholder="请选择经销商" clearable filterable @change="getAreaList">
            <el-option
              v-for="item in dealerOptions"
              :key="item.id"
              :label="item.dealerName"
              :value="item.id">
            </el-option>
          </el-select>
          <el-button size="mini" type="primary" class="gjBtn" @click="openAllAddr">地区设置</el-button>
         </div>
        </el-form-item> -->
        <!-- <el-form-item prop="customerId" label="客户">
          <el-select v-model="ruleForm.handleCustomerId" placeholder="请选择客户" clearable filterable>
            <el-option
                v-for="item in customerOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item prop="remark" label="备注">
          <el-input v-model="ruleForm.remark" type="textarea" rows="2" placeholder="请输入备注"></el-input>
        </el-form-item>
        <el-form-item prop="isBind" label="是否关联产品/盒" v-if="ruleForm.type === 3">
          <el-switch v-model="ruleForm.isBind"></el-switch>
        </el-form-item>
        <template v-if="ruleForm.isBind">
          <el-row>

            <el-form-item prop="syncHandleDto.pageId" label="关联页面">
              <el-row class="disflex">
              <el-select v-model="ruleForm.syncHandleDto.pageId" placeholder="请选择关联页面" filterable>
                  <el-option :label="item.title" :value="item.id" v-for="item in pagesOptions" :key="item.id"></el-option>
              </el-select>
              <el-button size="mini" type="primary" class="gjBtn" @click="openAdvanced">高级配置</el-button>
              </el-row>
            </el-form-item>
            <el-form-item prop="syncHandleDto.type" label="类型">
              <el-select v-model="ruleForm.syncHandleDto.type" placeholder="请选择类型" @change="getUsedCount2">
                  <el-option
                    v-for="item in TypeOptions2"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
                <span style="padding-left: 20px;">可使用数量: {{typeUsedCount2}}</span>
              </el-form-item>
              <el-form-item prop="ratio" label="比例" style="width: 40%;">
                <el-input v-model="ruleForm.ratio" placeholder="请输入数字"></el-input>
              </el-form-item>
              <el-form-item prop="syncHandleDto.number" label="数量" style="width: 40%;">
                <el-input v-model="ruleForm.syncHandleDto.number" placeholder="请输入数量" :readonly="true"></el-input>
              </el-form-item>
          </el-row>
        </template>
      </el-form>
      <!--内层高级配置弹窗-->
        <el-dialog
        width="30%"
        title="高级配置"
        :visible.sync="innerVisible"
        :append-to-body='true'
        >
        <el-form>
          <el-form-item prop="syncHandleDto.scanNum" label="失效次数">
            <el-input v-model="ruleForm.syncHandleDto.scanNum" placeholder="请输入失效次数"></el-input>
          </el-form-item>

          <el-form-item prop="syncHandleDto.outValue" label="扫码提醒阈值">
            <el-input v-model="ruleForm.syncHandleDto.outValue" placeholder="请输入数字"></el-input>
          </el-form-item>

          <el-form-item prop="syncHandleDto.verificationNum" label="验证次数">
            <el-input v-model="ruleForm.syncHandleDto.verificationNum" placeholder="请输入数字"></el-input>
          </el-form-item>

          <!-- <el-form-item prop="syncHandleDto.isRead" label="是否回读">
            <el-select v-model="ruleForm.syncHandleDto.isRead" placeholder="请选择">
              <el-option label="是" :value="true"></el-option>
              <el-option label="不需要回读" :value="false"></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item prop="syncHandleDto.idenModelId" label="查询模板">
            <el-select v-model="ruleForm.syncHandleDto.idenModelId" placeholder="请选择类型">
                <el-option
                  v-for="item in xiTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <span style="padding-left: 20px; color: #8A8A8A;">(顶级节点查询模版)</span>
          </el-form-item>
          <!-- <el-form-item prop="syncHandleDto.dealerId" label="经销商">
            <el-select v-model="ruleForm.syncHandleDto.dealerId" placeholder="请选择经销商" clearable filterable>
                <el-option
                  v-for="item in dealerOptions"
                  :key="item.id"
                  :label="item.dealerName"
                  :value="item.id">
                </el-option>
              </el-select>
          </el-form-item> -->
        </el-form>
      </el-dialog>

      <!--内层经销商地区配置弹窗-->
       <el-dialog
        width="30%"
        title="地区设置"
        :visible.sync="innerVisible2"
        :append-to-body='true'
        >
         <el-checkbox-group v-model="checkList">
          <el-checkbox :label="item.county" v-for="(item, index) in areaList" :key="item.county">
              <el-cascader size="large" :options="options" v-model="item.data" @change="handleChange(index, arguments)" placeholder="请选择省市区" clearable></el-cascader>
          </el-checkbox>
         </el-checkbox-group>
       </el-dialog>

      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button class="btncss" @click="confirmCreateHandle()">确 定</el-button>
      </div>
    </el-dialog>


    <!--查看弹窗----->
    <SeeCustomDialog ref="seeCustomDialog"></SeeCustomDialog>

    <!--绑定内部页面弹窗-->
    <InsidePageDialog ref="insidePageDialog" @getcheckPageArr="getcheckPageArr" :checkPageArr="checkPageArr"></InsidePageDialog>
    <!--绑定经销商-->
    <!-- <DistributorDialog ref="distributorDialog"></DistributorDialog> -->
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import SeeCustomDialog from '@/components/seeCustomDialog.vue'
import InsidePageDialog from './insidePageDialog.vue'
// import DistributorDialog from './distributorDialog'
import { fetchCreatePageList, fetchBindPageBatchList,fetchCreateHandle, fetchGetCustomCountByType, fetchDeleteCreatePage, fetchCheckDepartment, fetchIdenModelList, fetchDealerAllList, customerAllList,fetchPagesAllList, fetchNowPercent } from '@/api/customPage'
import DevicePrintComponent from "@/pages/createPageList/components/Print/DevicePrintComponent";
import Print from 'vue-print-nb'
import {fetchBindPageList, fetchCustomerAllList} from "../../../api/customPage";
import {
    provinceAndCityData,
    regionData,
    provinceAndCityDataPlus,
    regionDataPlus,
    CodeToText,
    TextToCode
} from 'element-china-area-data'
export default {
  name: "customlist",
  components: {

    commonTable,
    SeeCustomDialog,
    InsidePageDialog,
    // DistributorDialog
  },
  directives: {
    Print
  },
  data() {
    let token = localStorage.getItem("token")
    return {
      printParam: {type: 1},
      printShow: false,
      hasPermi: true,
      pickerValue: "", //下单时间
      loading:false,
      total: 0, //总条数
      currentPage: 1, //当前页数
      start:'',
      end:'',
      tableData: [],
      pageNum: 1,
      pageSize: 10,

      // 弹窗
      dialogFormVisible: false,
      ruleForm: {
        number: "",
        remark : "",
        scanNum: '', // -1为无限
        outValue: '',
        verificationNum: '',
        type: "", // 类型
        idenModelId: "", // 查询模板
        isRead: false,
        dealerId: null, // 经销商ID
        ratio: '',
        isBind: false, // 是否关联产品/盒
        isAppoint: false, // 是否制定文件
        handleCustomerId:null,
        syncHandleDto:{
            pageId: '',
            type:'',
            number: '',
            scanNum: '',
            verificationNum:'',
            outValue:'',
            isRead: '',
            idenModelId: '',
            dealerId:'',
          handleCustomerId:null,
        }
      },
      xiTypeOptions: [
        // {
        //   value: 0,
        //   label: '默认'
        // },
        // {
        //   value: 1,
        //   label: '次抛'
        // },
        // {
        //   value: 2,
        //   label: '面膜'
        // },
        // {
        //   value: 3,
        //   label: '53度'
        // },
        // {
        //   value: 4,
        //   label: '42度'
        // },
        // {
        //   value: 5,
        //   label: '60度'
        // },
      ],
      TypeOptions: [
        {
          value: 1,
          label: '宣传物料（海报、彩页、手册、手提袋、纸巾包、钥匙扣等）'
        },
        {
          value: 2,
          label: '产品'
        },
        // {
        //   value: 3,
        //   label: '箱标'
        // },
        {
          value: 4,
          label: '证书'
        },
        // {
        //   value: 5,
        //   label: '盒标'
        // },
      ],
      TypeOptions2: [
        {
          value: 2,
          label: '产品'
        },
        {
          value: 5,
          label: '盒标'
        },
      ],
      typeUsedCount: 0,
      typeUsedCount2:0,
      rules: {
        scanNum: [{ required: true, message: "请输入失效次数", trigger: "blur" }],
        number: [
          { required: true, message: "请输入数量", trigger: "blur" },
          { pattern: /^[1-9]\d*$/, message: '请输入正整数', trigger: 'blur'}
        ],
        ratio: [
          { required: true, message: "请输入比例", trigger: "blur" },
          { pattern: /^[1-9]\d*$/, message: '请输入正整数', trigger: 'blur'}
        ],
        outValue: [{ required: true, message: "请输入数字", trigger: "blur" }],
        verificationNum: [{ required: true, message: "请输入数字", trigger: "blur" }],
        type: [{ required: true, message: "请选择类型", trigger: "change" }],
        idenModelId:[{ required: true, message: "请选择模版类型", trigger: "change" }],
        // isRead:[{ required: true, message: "请选择是否回读", trigger: "change" }],
        "syncHandleDto.pageId": [{ required: true, message: "请选择页面", trigger: "change" },
        ],
        "syncHandleDto.type":[{ required: true, message: "请选择类型", trigger: "change" },
        ],
        "syncHandleDto.number" : [
          { required: true, message: "请输入数量", trigger: "blur" },
          { pattern: /^[1-9]\d*$/, message: '请输入正整数', trigger: 'blur'}
        ],
        "syncHandleDto.scanNum":[{ required: true, message: "请输入失效次数", trigger: "blur" }],
        "syncHandleDto.outValue":[{ required: true, message: "请输入数字", trigger: "blur" }],
        "syncHandleDto.verificationNum":[{ required: true, message: "请输入数字", trigger: "blur" }],
        "syncHandleDto.isRead": [{ required: true, message: "请选择是否回读", trigger: "change" }],
        "syncHandleDto.idenModelId":[{ required: true, message: "请选择模版类型", trigger: "change" }],
      },
      curId: null,
      // 一码多权
      curUserPage: '',
      checkPageArr: [],
      dealerOptions: [], // 经销商下拉菜单
      customerOptions: [], // 客户下拉菜单
      pagesOptions:[],
      // 内层弹窗
      innerVisible: false,
      innerVisible2: false,
      areaList: [], // 销售地区
      areaListTotal: 0,
      options: regionData, // 级联菜单数据源
      checkList: [],

      uploadUrl: this.$store.state.uploadFileUrl,
      upParams: {
        token: token
      },
      fileList: [],
      bindLotNumber:null,
      classId: ''
    };
  },
  created() {
    this.selectOrdersInfoPcAsync();
    if(JSON.parse(localStorage.getItem('isSourceAdmin'))) {
      this.hasPermi = false
    }
    // 获取经销商不分页
    this.getDealerOptions()
    //获取客户
    this.getCustomerOptions()
    // 获取页面页面列表不分页
    this.getPagesOptions()
  },
  watch:{
    ruleForm:{
        deep:true, //开启深度监听
        handler(v){
            console.log(v.ratio)
            console.log(v.number)
            if(v.ratio && v.number) {
              this.ruleForm.syncHandleDto.number = v.ratio * v.number
            }
        }
    },
    // classId(newvalue){
    //   this.classId = newvalue
    // }
  },
  filters: {
  },
  props:{
    serchParams:{
      type :Object,
    },
  },
  methods: {
    async getBindLotNumber(bindLotNumber){
      let params={
        lotNumber:bindLotNumber
      }
      const result = await fetchBindPageBatchList(params)
      for(let i=0; i<result.data.list.length; i++) {
        let item = result.data.list[i]
        if(item.lotNumberList && item.lotNumberList.length) {
          for(let j=0; j< item.lotNumberList.length; j++) {
            let lotItem = item.lotNumberList[j]
            lotItem.percent = ((lotItem.count/lotItem.allNum) * 100).toFixed(2)
            lotItem.timerId = 'loadTimer'+lotItem.lotNumber;
            if(lotItem.count!==lotItem.allNum) {
              window[lotItem.timerId] = setInterval(function(){
                that.getNowPercent(lotItem.timerId, lotItem.lotNumber, i, j)
                console.log(lotItem.timerId+'执行中')
              },3000)
            }
          }
        }
      }
      console.log('改造数据后result.data.list', result.data.list)
      this.tableData = result.data.list
      this.total = result.data.total
      console.log(result)
      this.getIdenModelList()
    },
    changeIsAppoint(data) {
      if(data) {
        // this.ruleForm.number = ""
      }
    },
    // 下载模版
    downLoadFile() {
      var src = 'https://mzmpic.oss-cn-beijing.aliyuncs.com/%E6%A0%87%E8%AF%86%E6%A8%A1%E6%9D%BF.xlsx'
      var a = document.createElement('a');
      a.href = src
      a.download = '标识模版.xlsx';
      a.click();
    },
    // 上传文件
    handleSuccess(response, file, fileList) {
      if(response.code === 200) {
        this.fileList=fileList
        this.ruleForm.number = response.data.num
      }else {
        this.$message.error(response.message)
        this.fileList= []
      }
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    beforeUpload(file) {
      // 允许上传的文件格式列表
      let acceptList = ["xlsx", "xls"]
      // 根据文件名获取文件的后缀名
      let fileType = file.name.split('.').pop().toLowerCase()
      // 判断文件格式是否符合要求
      if (acceptList.indexOf(fileType) === -1) {
          this.$message.error('只能上传 xlsx/xls 格式的文件 !');
          return false
      }
      if(!this.ruleForm.number) {
        this.$message.error('请填写标识数量')
        return false
      }
      // 判断文件大小是否符合要求
      // if (file.size / 1024 / 1024 > 1) {
      //     this.$message.error('上传文件大小不能超过 1M !');
      //     return false
      //  }
    },
    getPagesOptions() {
      let params = {
        companyId: JSON.parse(localStorage.getItem('info')).companyId,
        type: 0,
      }
      fetchPagesAllList(params).then(res => {
        this.pagesOptions = res.data
      })
    },
    getDealerOptions() {
      let params = {
        companyId: JSON.parse(localStorage.getItem('info')).companyId,
      }
      fetchDealerAllList(params).then(res => {
        if(res.data && res.data.length) {
          this.dealerOptions = res.data
        }
      })
    },
    getCustomerOptions() {
      customerAllList().then(res => {
        if(res.data && res.data.length) {
          this.customerOptions = res.data
        }
      })
    },
    changeIdenModelId(val) {
      this.ruleForm.idenModelId = val
    },
    lookPage(data) {
      this.$refs.seeCustomDialog.visible = true
      this.$refs.seeCustomDialog.init(data.id)
      // this.$router.push({ path: '/customPageShow', query: { customPageId: data.id }})
    },
    editPage(data) {
      this.$router.push({ path: '/customPage', query: { pageId: data.id , type:'editPage', templateType: 0 }})
    },
    checkRecord(data) {
       this.$router.push({ path: '/pageVipRecord', query: { pageId: data.id }})
    },
    async createHandle(data) {
      // 默认选中第一个
      this.ruleForm.type = this.TypeOptions[0].value
      this.typeUsedCount= await this.getUsedCount(this.ruleForm.type)
      if(this.typeUsedCount!== 0) {
        this.ruleForm.number = 1
      }else {
        this.ruleForm.number = 0
      }
      this.ruleForm.scanNum = -1
      this.ruleForm.outValue =  10
      this.ruleForm.verificationNum = 5
      this.ruleForm.ratio = ""
      this.ruleForm.isRead = false
      this.ruleForm.idenModelId = this.xiTypeOptions[0].value
      this.remark=""
      this.curId = data.id
      this.dialogFormVisible = true
      this.ruleForm.isAppoint = false
      this.fileList = []
      // TODO 继续设置初始化值
      this.resetInner()
    },
    // 删除页面
    delCreatePage(data) {
      if(data.lotNumberList.length!== 0) {
        this.$message({
          type: "error",
          message: "已经生成标识的页面，不能删除！",
        })
        return
      }
      this.$confirm("确定删除此页面吗?", "提示", {
        onConfirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info ",
      })
      .then(() => {
        let params = {
          id: data.id,
          isDel: true,
        }
        fetchDeleteCreatePage(params)
        .then(res=>{
          if(res.code == 200){
            this.$message({
              type: "success",
              message: "操作成功！",
            });
          }
          this.selectOrdersInfoPcAsync();
        })
      })
    },
    closeDialog() {
      // this.ruleForm = {
      //   number: "",
      //   scanNum: "",
      //   remark : "",
      //   type: undefined,
      // }
      this.typeUsedCount = 0
      this.typeUsedCount2 = 0
      this.ruleForm.syncHandleDto.number=''
      this.$refs.ruleForm.resetFields();
      this.resetInner()
      this.dialogFormVisible = false
    },
    resetInner() {
      this.typeUsedCount2 = 0
      this.ruleForm.dealerId = null
      this.ruleForm.ratio = ''
      this.ruleForm.handleCustomerId = null
      this.ruleForm.isBind=false
      this.ruleForm.syncHandleDto.scanNum = -1
      this.ruleForm.syncHandleDto.outValue =  10
      this.ruleForm.syncHandleDto.verificationNum = 5
      this.ruleForm.syncHandleDto.isRead = false
      this.ruleForm.syncHandleDto.idenModelId = this.xiTypeOptions[0].value
      this.ruleForm.syncHandleDto.pageId = ''
      this.ruleForm.syncHandleDto.type = ''
      this.ruleForm.syncHandleDto.number = ''
      this.ruleForm.syncHandleDto.dealerId = ''
      this.checkList = []
      this.areaList = []

    },
    // 跳转标识页面列表
    goHandlePageList(lotNumber, customPageId, isRead, isBind, type) {
      this.$router.push({ path: '/handlePageList', query: { lotNumber: lotNumber , customPageId: customPageId, isRead: isRead, isBind: isBind, type: type}})
    },
    // 生成标识
    confirmCreateHandle() {
      let v= this
      this.$refs.ruleForm.validate(async (valid) => {
          if(Number(v.ruleForm.number) > this.typeUsedCount) {
            this.$message({
              message: '超出可使用数量',
              type: 'warning'
            });
            return
          }
          if(this.ruleForm.isBind) {
            if(Number(v.ruleForm.syncHandleDto.number) > this.typeUsedCount2) {
              this.$message({
                message: '超出可使用数量',
                type: 'warning'
              });
              return
            }
          }
          if(this.ruleForm.isAppoint){ // 指定文件

            if(this.fileList.length===0) {
              this.$message({
                message: '请上传文件',
                type: 'warning'
              });
              return
            }
          }
          if (valid) {
             const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            try {
              let params = {
                pageId: v.curId,
                number: Number(v.ruleForm.number),
                remark: v.ruleForm.remark,
                type: v.ruleForm.type,
                scanNum: v.ruleForm.scanNum,
                idenModelId: v.ruleForm.idenModelId,
                isRead: v.ruleForm.isRead,
                outValue: v.ruleForm.outValue,
                verificationNum: v.ruleForm.verificationNum,
                dealerId: v.ruleForm.dealerId,
                isAppoint: v.ruleForm.isAppoint,
                handleCustomerId:v.ruleForm.handleCustomerId
              }
              if(v.ruleForm.isAppoint) {
                params.fileUrl = v.fileList[0]['response']['data']['url']
              }
              if(this.ruleForm.isBind) {
                params.ratio = Number(v.ruleForm.ratio)
                params.isBind = this.ruleForm.isBind
                params.syncHandleDto = this.ruleForm.syncHandleDto
              }
               let areaArr= []
              if(this.checkList.length) {
                for(let i=0; i<this.checkList.length; i++) {
                  areaArr.push(this.areaList.filter(item => item.county ===this.checkList[i])[0])
                }
              }
              params.dealerAreas = areaArr
              let res= await fetchCreateHandle(params);
              this.dialogFormVisible = false;
              if(res.code === 200) {
                this.$router.push('/createPageList')
                this.$message({
                  type: "success" ,
                  message: "操作成功！",
                });
                this.selectOrdersInfoPcAsync()
              }
               loading.close()
            } catch (error) {
              loading.close()
              // this.$message({
              //     type: "error" ,
              //     message: "请稍后再试",
              // });
            }
          } else {
            console.log("error submit!!");
            return false;
          }
        });
    },
    /**@method 获取页面列表 */
    async selectOrdersInfoPcAsync(classId) {
      if(classId) {
        this.classId = classId
      }else {
        this.classId = ""
      }
      let that = this
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        lotNumber: this.bindLotNumber,
        type: 0,
        classId:this.classId,
        pageName:that.serchParams.pageName,
        remarks:that.serchParams.remarks
      }
      // if(this.classId) {
      //   params.classId = this.classId
      // }
      console.log(params)
      const result = await fetchCreatePageList(params)
      // this.tableData = result.data.list
      // 做进度条
      for(let i=0; i<result.data.list.length; i++) {
        let item = result.data.list[i]
        if(item.lotNumberList && item.lotNumberList.length) {
          for(let j=0; j< item.lotNumberList.length; j++) {
            let lotItem = item.lotNumberList[j]
            lotItem.percent = (lotItem.count/lotItem.allNum) * 100
            lotItem.timerId = 'loadTimer'+lotItem.lotNumber;
            if(lotItem.count!==lotItem.allNum) {
              window[lotItem.timerId] = setInterval(function(){
                that.getNowPercent(lotItem.timerId, lotItem.lotNumber, i, j)
                console.log(lotItem.timerId+'执行中')
              },3000)
            }
          }
        }
      }
      console.log('改造数据后result.data.list', result.data.list)
      this.tableData = result.data.list
      this.total = result.data.total
      console.log(result)
      this.getIdenModelList()
    },
    getNowPercent(timerId, lotNumber, i, j) {
      let params = {
        lotNumber,
      }
      fetchNowPercent(params).then(res => {
        if(res.data.allNum === res.data.regNum) {
          console.log(timerId+'已结束')
          clearInterval(window[timerId])
        }else {
          let percent = (res.data.regNum/res.data.allNum) * 100
          this.$set(this.tableData[i].lotNumberList[j], 'percent', percent)
        }
        this.$set(this.tableData[i].lotNumberList[j], 'count', res.data.regNum)
      })
    },
    getIdenModelList(){
        let params = {
          companyId: JSON.parse(localStorage.getItem('info')).companyId,
          pageNum: 1,
          pageSize: 1000,
        }
        fetchIdenModelList(params).then(res => {
          if(res.code === 200) {
            let arr = res.data.list.map(item => {
              return {
                label: item.idenModelNumber,
                value: item.id
              }
            })
            this.xiTypeOptions = [{ label: '默认', value: 0 },...arr]
            console.log('this.xiTypeOptions', this.xiTypeOptions)
          }
        })
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.selectOrdersInfoPcAsync();
    },
    clearSelection() {
      this.$refs.commonTable.clearSelection()
    },
    // 多选
    handleSelectionChange(arr) {
      this.$emit('handleSelectionChange', arr)
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.selectOrdersInfoPcAsync();
    },
    getUsedCount(data) {
      this.resetInner()
      let params= {
        companyId: JSON.parse(localStorage.getItem('info')).companyId,
        type: data
      }
      fetchGetCustomCountByType(params).then(res => {
        this.typeUsedCount=res.data.count - res.data.usedCount
      })
    },
    getUsedCount2(data) {
      let params= {
        companyId: JSON.parse(localStorage.getItem('info')).companyId,
        type: data
      }
      fetchGetCustomCountByType(params).then(res => {
        this.typeUsedCount2=res.data.count - res.data.usedCount
      })
    },
    // 绑定内部页面
    bindInsidePage(row) {
      this.curUserPage = row.id
      this.checkPageArr = row.pages? row.pages.map(item => String(item.id)) : []
      this.$refs.insidePageDialog.visible = true
    },
    getcheckPageArr(data) {
      this.checkPageArr = data
      let params = {
        pageId: this.curUserPage,
        pageIds: this.checkPageArr,
        type: 1,
      }
      fetchCheckDepartment(params).then( res => {
        this.selectOrdersInfoPcAsync();
      })
    },
    // 高级配置
    openAdvanced() {
      this.innerVisible = true
    },
    openAllAddr() {
      this.innerVisible2 = true
      // this.checkList = []
    },
    getAreaList(val) {
      let arr = this.dealerOptions.filter(item => item.id === val)[0].dealerAreas
      this.areaList = arr.map(item => {
        return {
          ...item,
          data: [item.province, item.city, item.county]
        }
      })
      this.areaListTotal = this.areaList.length
    },
    handleChange(index, e) {
      console.log(e)
      let value = e[0]
      this.areaList[index] = {
        province: value[0],
        city: value[1],
        county: value[2],
        address: CodeToText[value[0]] + CodeToText[value[1]] + CodeToText[value[2]],
        data: [value[0], value[1], value[2]]
      }
    }
    // // 绑定经销商
    // bindDistributor() {
    //   this.$refs.distributorDialog.visible = true
    // }

  },
};
</script>

<style scoped lang='scss'>
.lotSpan{
  // border: 1px solid #ccc;
  padding: 0 10px;
  cursor: pointer;
  width: auto;
  line-height: 30px;
  text-decoration: underline;
}
.disflex{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.gjBtn{
  height: 33px;
  padding: 5px;
  margin: 0 0 0 10px;
}
.linkIcon{
  width: 20px;
  height: 20px;
  display: inline;
  vertical-align: middle;
}
.coverImg{
  width: 60px;
}
/deep/ .el-upload--text{
  width: inherit;
  height: auto;
  border: none;
}
.el-upload__tip{
  color: #51CDCB;
}
</style>
