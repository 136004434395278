<template>
  <div>
    <el-dialog
      title=""
      width="600px"
      :modal-append-to-body="false"
      :visible.sync="visible"
      :close-on-click-modal="false"
    >
      <el-checkbox-group v-model="checkList">
          <el-checkbox v-for="item in tableData" :key="item.id" :label="item.id">{{item.title}}</el-checkbox>
      </el-checkbox-group>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogClose">取 消</el-button>
        <el-button class="btncss" @click="confirmPage()">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { fetchCreatePageList } from '@/api/customPage'
export default {
  components:{
  },
  props: ['checkPageArr'],
  data(){
    return {
      visible: false,
      tableData: [],
      checkList: [],
      pageNum: 1,
      pageSize: 1000,
    }
  },
  computed: {

  },
  created(){
    this.init()
  },
  methods:{
    async init() {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        type: 1,
      }
      const result = await fetchCreatePageList(params)
      this.tableData = result.data.list
    },
    confirmPage() {
      // console.log(this.checkList)
      this.$emit('getcheckPageArr', this.checkList)
      this.visible = false
    },
    dialogClose() {
      this.visible = false
      this.checkList = []
    }
  },
  watch: {
    visible (val) {
      if(this.visible) {
        this.checkList = this.checkPageArr
      }
    }
  },
}
</script>
<style scoped>
.container .mobileContainer {
  height: 550px!important;
}
</style>