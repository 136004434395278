<template>
  <div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="用户页面" name="first">
        <el-form :model="serchParams" class="demo-form-inline">
          <el-row style="padding-top: 20px;">
            <el-col :span="16">
              <el-form-item label="页面类别" label-width="100px" label-position="left" class="fromItem">
                <el-select v-model="classId" placeholder="请选择" clearable @change="classIdChange">
                  <el-option
                      v-for="item in typeList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="页面标题" label-width="100px" label-position="left" class="fromItem">
                <el-input v-model="serchParams.pageName" placeholder="请输入页面标题" clearable></el-input>
              </el-form-item>
              <el-form-item label="备注" label-width="80px" label-position="left" class="fromItem">
                <el-input v-model="serchParams.remarks" placeholder="请输入备注" clearable></el-input>
              </el-form-item>
              <el-button type="primary" class="myBtn" @click="serchquery">搜索</el-button>
              <el-button type="primary" class="myBtn" @click="reset">重置</el-button>
            </el-col>
            <el-col :span="5">

              <el-button type="primary" class="myBtn" @click="goPageList">标识检索</el-button>
            </el-col>
            <el-col :span="3">
               <el-button type="primary" class="myBtn" style="float: right;" :disabled="selectArr.length===0" @click="openSetClassDialog">
                 <template>设置分类</template>
              </el-button>
            </el-col>

            <!-- <el-col :span="5">
              <el-form-item label="标识" label-width="100px" label-position="left">
                <el-input v-model="serchParams.handle"></el-input>
              </el-form-item>
            </el-col> -->
            <!-- <el-col :span="4">
              <el-form-item>
                <el-button type="primary" class="myBtn" @click="search(1)">搜索</el-button>
                <el-button type="primary" class="myBtn" @click="search(3)">重置</el-button>
              </el-form-item>
            </el-col> -->
          </el-row>
        </el-form>
       <tableList v-if="activeName == 'first'" :serchParams="serchParams" @handleSelectionChange="handleSelectionChange" ref="createList1"></tableList>
      </el-tab-pane>
<!--      <el-tab-pane label="内部页面" name="second">-->
        <!-- <el-form :model="serchParams2">
          <el-row style="padding-top: 20px;">
            <el-col :span="5">
              <el-form-item label="标识" label-width="100px" label-position="left">
                <el-input v-model="serchParams2.handle"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item>
                <el-button type="primary" class="myBtn" @click="search(2)">搜索</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form> -->
<!--        <tableList2 v-if="activeName == 'second'" :serchParams="serchParams2.handle" ref="createList2"></tableList2>-->
<!--      </el-tab-pane>-->
    </el-tabs>
    <SetClassDialog ref="setClassDialog"></SetClassDialog>
  </div>
</template>

<script>
import tableList from './components/tableList'
// import tableList2 from './components/tableList2'
import {noPage, bindPageClass} from "../../api/customPageClass";
import SetClassDialog from './components/setClassDialog'
export default {
  name: "createPageIndex",
  components:{
    tableList,
    // tableList2,
    SetClassDialog
  },
  data() {
    return {
      activeName: 'first',
      serchParams: {
        handle: '',
        pageName:'',
        remarks:''
      },
      serchParams2:{
        handle: ''
      },
      typeList:[],
      pageOptions: [],
      classId: '',
      flag: 2,
      selectArr:[],

    };
  },
  computed: {
  },
  watch:{
    $route(to,from) {
      this.init()
      this.$nextTick(() => {
        this.search(this.flag)
      })
    },
  },
  created(){
    this.init()
    this.search()
  },
  methods:{
    reset() {
      this.classId = ''
      this.serchParams.pageName=''
      this.serchParams.remarks=''
      this.$refs.createList1.pageNum = 1
      this.$refs.createList1.selectOrdersInfoPcAsync(this.classId)

    },
    openSetClassDialog() {
      this.$refs.setClassDialog.open(0)
    },
    handleSelectionChange(data) {
      console.log('this.selectArr', this.selectArr)
      this.selectArr = data
    },
    setPageClass(classId) {
      let ids = this.selectArr.map(item => item.id)
      let params = {
        classId: parseInt(classId),
        pageIds: ids,
        type: 0,
      }
      bindPageClass(params).then(res => {
        console.log(res)
        this.selectArr = []
        this.$refs.createList1.clearSelection();
        this.$message.success('设置成功')
        this.$refs.setClassDialog.close()
      })
    },
    init(){
      noPage({type:0}).then(response=>{
        if (response.data){
          this.typeList = response.data
        }
      })
    },
    search(flag) {
      console.log(this.serchParams)
      console.log(this.serchParams2)
      this.flag = flag
      if(flag == 1) {
        this.$router.push({ path: '/handlePageList', query: { handle: this.serchParams.handle }})
      }else if(flag == 2) {
        this.$refs.createList2.selectOrdersInfoPcAsync()
      }else {
        this.$refs.createList1.selectOrdersInfoPcAsync()
      }
    },
    classIdChange(data) {
      this.$refs.createList1.pageNum = 1
      this.$refs.createList1.selectOrdersInfoPcAsync(data)
    },
    serchquery(){
      console.log(this.serchParams)
      this.$refs.createList1.pageNum = 1
      this.$refs.createList1.selectOrdersInfoPcAsync(this.classId)
    },
    goPageList() {
      // this.$router.push({ path: '/handlePageList', query: { handle: this.serchParams.handle }})
      this.$router.push({ path: '/handlePageList'})
    }
  }
};
</script>

<style scoped>
.w200{
  width: 200px;
}
.myBtn{
  margin-left: 10px;
}
.fromItem{
  display: inline-block;
}
</style>
